<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('shareholder.name')"
        prop="name"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-input type="text" v-model="form.name" placeholder="Shareholder Name"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('shareholder.title')"
        prop="title"
      >
        <el-input type="text" v-model="form.title" placeholder="Title of shareholder"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('shareholder.socialsecurity')"
        prop="securitysocial"
      >
        <el-input type="text" v-model="form.securitysocial" placeholder="e.g 554-55-998" v-mask="'NNN-NN-NNNN'"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('shareholder.address')"
        prop="address"
      >
        <el-input type="text" v-model="form.address" placeholder="Shareholder Address"></el-input>
      </el-form-item>

      <div class="row">
        <div class="col-4">
          <el-form-item
            :label="$t('shareholder.city')"
            prop="city"
          >
            <el-select v-model="form.city" placeholder="Select city" class="w-100">
              <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
            <el-form-item
              :label="$t('State')"
              prop="state"
            >
            <el-select class="w-100" v-model="form.state" placeholder="Select state" @change="getCities">
              <el-option v-for="item in states" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item
            :label="$t('shareholder.zip')"
            prop="zip"
          >
            <el-input type="text" v-model="form.zip" placeholder="e.g 12345"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-7">
          <el-form-item
            :label="$t('shareholder.birthday')"
            prop="birthday"
          >
            <el-date-picker
              type="date"
              v-model="form.birthday"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="col-xl-5">
          <el-form-item
            :label="$t('shareholder.share')"
            prop="share"
          >
            <el-input type="text" v-model="form.share" placeholder="%"></el-input>
          </el-form-item>
        </div>
      </div>

      <el-form-item
        :label="$t('shareholder.email')"
        prop="email"
      >
        <el-input type="email" v-model="form.email" placeholder="example@mail.com"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('shareholder.phone')"
        prop="phone"
      >
        <el-input type="text" v-model="form.phone" placeholder="e.g (414) 639-1121" v-mask="'(###) ###-####'"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save data</el-button>
    </el-form>
  </div>
</template>

<script>
import location from "@/services/api/location";
import shareholder from "@/services/api/shareholder";

export default {
  props: ["data"],
  data() {
    return {
      cities: [],      
      states: [],
      form: {
        id: null,
        name: null,
        title: null,
        securitysocial: null,
        address: null,
        city: null,
        zip: null,
        birthday: null,
        share: null,
        email: null,
        phone: null,
        corporate: this.$route.params.id
      }
    };
  },
  mounted() {
    location.state().then(response => {
      this.states = response;
    });
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.name = this.data.name;
      this.form.title = this.data.title;
      this.form.securitysocial = this.data.securitySocial;
      this.form.address = this.data.address;
      this.form.city = this.data.cityId;
      this.form.zip = this.data.zip;
      this.form.birthday = this.data.birthDay;
      this.form.share = this.data.share;
      this.form.email = this.data.email;
      this.form.phone = this.data.phone;
      this.form.corporate = this.data.corporateId;
    }
  },
  methods: {
    getCities(){

      location.city(this.form.state).then(response => {
        this.cities = response;
      });     

    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          shareholder
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          shareholder
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
